import Ember from 'ember';
import EmberRouter from '@embroider/router';
import config from 'frontend/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('authenticated', { path: 'a' }, function () {
    // if jwt and user has email
    this.route('index', { path: '/' });
    this.route('instagram-authorize', { path: 'insta-auth' });
    this.route('menu');
  });
  this.route('index', { path: '/' });
  this.route('instagram-deletion');
  this.route('instagram-redirect');
  this.route('legal', function () {
    this.route('privacy');
    this.route('terms-and-conditions');
    this.route('terms-of-use');
  });
  this.route('login');
  this.route('menu');
  this.route('instagram-authorize', { path: 'insta-auth' });
  this.route('instagram-api-change-error');
  this.route('subscribe');
  this.route('subscribe-success');
  if (Ember.testing) {
    this.route('instagram-authorize-mock');
    this.route('stripe-checkout-mock');
    this.route('stripe-billing-mock');
  }
  this.route('error', { path: '/:data' });
  this.route('404', { path: '/*path' });
});
