
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("frontend/app", function(){ return i("frontend/app.js");});
d("frontend/authenticators/custom", function(){ return i("frontend/authenticators/custom.js");});
d("frontend/config/environment", function(){ return i("frontend/config/environment.js");});
d("frontend/constants", function(){ return i("frontend/constants.js");});
d("frontend/router", function(){ return i("frontend/router.js");});
d("frontend/services/api", function(){ return i("frontend/services/api.js");});
d("frontend/services/external-link-manager", function(){ return i("frontend/services/external-link-manager.js");});
d("frontend/services/location", function(){ return i("frontend/services/location.js");});
d("frontend/services/metrics", function(){ return i("frontend/services/metrics.js");});
d("frontend/services/sentry", function(){ return i("frontend/services/sentry.js");});
d("frontend/services/session", function(){ return i("frontend/services/session.js");});
d("frontend/services/link-manager", function(){ return i("frontend/services/link-manager.js");});
d("frontend/services/page-title", function(){ return i("frontend/services/page-title.js");});
d("frontend/services/cookies", function(){ return i("frontend/services/cookies.js");});
d("frontend/initializers/ember-simple-auth", function(){ return i("frontend/initializers/ember-simple-auth.js");});
d("frontend/session-stores/application", function(){ return i("frontend/session-stores/application.js");});
d("frontend/utils/inject", function(){ return i("frontend/utils/inject.js");});
d("frontend/utils/is-fastboot", function(){ return i("frontend/utils/is-fastboot.js");});
d("frontend/utils/location", function(){ return i("frontend/utils/location.js");});
d("frontend/utils/objects-are-equal", function(){ return i("frontend/utils/objects-are-equal.js");});
d("frontend/component-managers/glimmer", function(){ return i("frontend/component-managers/glimmer.js");});
d("frontend/initializers/app-version", function(){ return i("frontend/initializers/app-version.js");});
d("frontend/services/-ensure-registered", function(){ return i("frontend/services/-ensure-registered.js");});
d("frontend/services/dialog-stack-provider", function(){ return i("frontend/services/dialog-stack-provider.js");});
d("frontend/metrics-adapters/base", function(){ return i("frontend/metrics-adapters/base.js");});
d("frontend/metrics-adapters/facebook-pixel", function(){ return i("frontend/metrics-adapters/facebook-pixel.js");});
d("frontend/instance-initializers/sentry-performance", function(){ return i("frontend/instance-initializers/sentry-performance.js");});
d("frontend/templates/application", function(){ return i("frontend/templates/application.hbs");});
d("frontend/routes/application", function(){ return i("frontend/routes/application.js");});
d("frontend/routes/authenticated", function(){ return i("frontend/routes/authenticated.js");});
d("frontend/templates/authenticated/index", function(){ return i("frontend/templates/authenticated/index.js");});
d("frontend/routes/authenticated/index", function(){ return i("frontend/routes/authenticated/index.js");});
d("frontend/templates/authenticated/instagram-authorize", function(){ return i("frontend/templates/authenticated/instagram-authorize.hbs");});
d("frontend/templates/authenticated/menu", function(){ return i("frontend/templates/authenticated/menu.hbs");});
d("frontend/templates/index", function(){ return i("frontend/templates/index.js");});
d("frontend/routes/index", function(){ return i("frontend/routes/index.js");});
d("frontend/templates/instagram-authorize", function(){ return i("frontend/templates/instagram-authorize.hbs");});
d("frontend/routes/instagram-authorize", function(){ return i("frontend/routes/instagram-authorize.js");});
d("frontend/routes/instagram-redirect", function(){ return i("frontend/routes/instagram-redirect.js");});
d("frontend/templates/login", function(){ return i("frontend/templates/login.js");});
d("frontend/routes/login", function(){ return i("frontend/routes/login.js");});
d("frontend/templates/subscribe-success", function(){ return i("frontend/templates/subscribe-success.js");});
d("frontend/routes/subscribe-success", function(){ return i("frontend/routes/subscribe-success.js");});
d("frontend/templates/subscribe", function(){ return i("frontend/templates/subscribe.js");});
d("frontend/routes/subscribe", function(){ return i("frontend/routes/subscribe.js");});
d("frontend/templates/404", function(){ return i("frontend/templates/404.js");});
d("frontend/templates/error", function(){ return i("frontend/templates/error.js");});
d("frontend/templates/instagram-api-change-error", function(){ return i("frontend/templates/instagram-api-change-error.js");});
d("frontend/templates/instagram-authorize-mock", function(){ return i("frontend/templates/instagram-authorize-mock.hbs");});
d("frontend/templates/instagram-deletion", function(){ return i("frontend/templates/instagram-deletion.js");});
d("frontend/templates/legal", function(){ return i("frontend/templates/legal.js");});
d("frontend/templates/loading", function(){ return i("frontend/templates/loading.hbs");});
d("frontend/templates/menu", function(){ return i("frontend/templates/menu.hbs");});
d("frontend/templates/stripe-billing-mock", function(){ return i("frontend/templates/stripe-billing-mock.hbs");});
d("frontend/templates/stripe-checkout-mock", function(){ return i("frontend/templates/stripe-checkout-mock.js");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("frontend/instance-initializers/setup-fetch", function(){ return i("frontend/instance-initializers/setup-fetch.js");});
  }


w._embroiderRouteBundles_ = [
  {
    names: ["legal.privacy"],
    load: function() {
      return import("frontend/assets/_route_/legal.privacy.js");
    }
  },
  {
    names: ["legal.terms-and-conditions"],
    load: function() {
      return import("frontend/assets/_route_/legal.terms-and-conditions.js");
    }
  },
  {
    names: ["legal.terms-of-use"],
    load: function() {
      return import("frontend/assets/_route_/legal.terms-of-use.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"name":"frontend","version":"0.0.0+f5440a33"});
}

