import styles from './subscribe-form.css';
import { modifier } from 'ember-modifier';

import { COUNTRY_CODES } from 'frontend/constants';

import svgJar from 'ember-svg-jar/helpers/svg-jar';

import preventDefault from 'ember-event-helpers/helpers/prevent-default';
import Combobox from 'ember-headlessui/components/combobox';
import { CheckoutButton } from 'frontend/components/get-started-button';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { tracked } from '@glimmer/tracking';
import { use } from 'ember-resources';
import { RemoteData } from 'reactiveweb/remote-data';

const scrollIntoView = modifier((element, [active]) => {
  if (active) {
    element.scrollIntoView({
      behaviour: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  }
});

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

export default class SubscribeFormComponent extends Component {
  @use request = RemoteData(() => 'https://ipinfo.io?token=1f1fb02d70fc5f');

  @tracked _selectedOption;
  @tracked query;

  get defaultCountryOption() {
    return this.countryOptions.find(
      ({ key }) => key === this.request.value?.country
    );
  }

  get countryOptions() {
    return COUNTRY_CODES.map((code) => {
      return {
        key: code,
        value: regionNames.of(code),
      };
    });
  }

  get selectedOption() {
    return this._selectedOption ?? this.defaultCountryOption;
  }

  @action
  onInputChange(event) {
    console.log(event.target.value);
    this.query = event.target.value;
  }

  @action
  onChange(value) {
    this._selectedOption = value;
  }

  @action
  onSubmit(value) {
    console.log(value);
    if (value) this.args.onSubmit(value);
  }

  displayValue(option) {
    return option?.value;
  }

  get filteredCountryOptions() {
    return this.countryOptions.filter(({ value }) =>
      value.toLowerCase().includes(this.query.toLowerCase())
    );
  }

  get article() {
    if (['GB', 'US', 'NL'].includes(this.selectedOption?.key)) {
      return ' the';
    }
    return '';
  }

  <template>
    <form
      class={{styles.form}}
      {{on
        "submit"
        (preventDefault (fn this.onSubmit this.selectedOption.key))
      }}
    >
      <Combobox
        @value={{this.selectedOption}}
        @onChange={{this.onChange}}
        as |combobox|
      >
        <div class={{styles.combo}}>
          <combobox.Label class={{styles.label}}>Send my photos to{{this.article}}</combobox.Label>
          <div>
            <div class={{styles.trigger}}>
              <combobox.Input
                class={{styles.input}}
                @displayValue={{this.displayValue}}
                @onChange={{this.onInputChange}}
                data-test-country-input
              />
              <combobox.Button
                class={{styles.combo-button}}
                data-test-country-trigger
              >{{svgJar "combobox"}}</combobox.Button>
            </div>
            <combobox.Options class={{styles.drop}} as |options|>
              {{#each this.filteredCountryOptions as |countryOption|}}
                <options.Option
                  class={{styles.option}}
                  @value={{countryOption}}
                  as |option|
                ><span
                    {{scrollIntoView option.active}}
                    data-test-country-option={{countryOption.key}}
                  >{{countryOption.value}}</span></options.Option>
              {{/each}}
            </combobox.Options>

          </div>
        </div>
      </Combobox>
      <div>
        <CheckoutButton
          data-test-checkout-btn
          type="submit"
        >Subscribe</CheckoutButton>
      </div>
    </form>
  </template>
}
