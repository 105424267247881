import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { LinkTo } from '@ember/routing';
import Container from 'frontend/components/container';
import Header from 'frontend/components/header';
import Section from 'frontend/components/section';
import Sections from 'frontend/components/sections';
import SectionFooter from 'frontend/components/section-footer';
import Link from 'frontend/components/link';
import { link } from 'ember-link';
import { on } from '@ember/modifier';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

import styles from './instagram-api-change-error.css';

export default RouteTemplate(
  <template>
    {{pageTitle "error"}}

    <Container>
      <:header>
        <Header>
          <LinkTo
            data-test-menu-link
            aria-label="Show menu"
            @route="menu"
          >{{svgJar "btn-menu"}}</LinkTo>
        </Header>
      </:header>
      <:main>
        <Sections>
          <Section class={{styles.error}}>
            <img
              alt="a plane exploding in flames"
              src="/images/illustrations/error.svg"
            />
            <h1 data-test-something-went-wrong>Temporarily Unavailable</h1>
            <p>Instagram has made changes to their API that are preventing us
              from accessing your photos. We are working on a solution and will
              have it fixed as soon as possible.</p>
            {{#let (link "index") as |l|}}
              <Link
                class={{styles.btn}}
                href={{l.url}}
                {{on "click" l.open}}
                data-test-return-homepage
              >Return to Homepage</Link>
            {{/let}}
          </Section>
          <SectionFooter />
        </Sections>
      </:main>
    </Container>
  </template>
);
