import Component from '@glimmer/component';
import PhotoPreview from 'frontend/components/photo-preview';
import RouteTemplate from 'ember-route-template';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { LinkTo } from '@ember/routing';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { service } from '@ember/service';

import { instaAuthUrl } from 'frontend/helpers/insta-auth-url';
import Container from 'frontend/components/container';
import Header from 'frontend/components/header';
import Section from 'frontend/components/section';
import Sections from 'frontend/components/sections';

import styles from './index.css';

const instagramAuthUrl = instaAuthUrl();

class AuthenticatedIndexRouteComponent extends Component {
  @service api;
  @service router;
  @service session;

  @action manageSubscription() {
    if (this.session.hasStripeCustomer) {
      this.api.visitCustomerBilling();
    } else {
      this.router.transitionTo('subscribe');
    }
  }

  get username() {
    return this.args.model.username;
  }

  get photos() {
    return this.args.model.photos.media;
  }

  get dateString() {
    if (this.args.model.photos.timeRange) {
      let date = new Date(this.args.model.photos.timeRange.until * 1000);
      return new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'full',
      }).format(date);
    }
    return '';
  }

  <template>
    <Container @mark={{true}}>
      <:header>
        <Header @mark={{true}}>
          <LinkTo
            data-test-menu-link
            aria-label="Show menu"
            @route="authenticated.menu"
          >{{svgJar "btn-menu"}}</LinkTo>
        </Header>
      </:header>
      <:main>
        <Sections class={{styles.sections}}>
          <Section class={{styles.section}}>
            {{#if this.username}}
              <h1 data-test-username class={{styles.username}}>Hello,
                {{this.username}}!</h1>
            {{/if}}
            <div class={{styles.boxes}}>

              {{#if this.session.hasInstagramToken}}
                <div data-test-photos class="{{styles.box}}">
                  <h2>Next Prints: {{this.dateString}}</h2>

                  {{#if this.photos.length}}
                    <PhotoPreview
                      class={{styles.photos}}
                      @photos={{this.photos}}
                      @columns={{5}}
                    />
                    <p class={{styles.photos-description}}>Your random selection
                      of photos to print. On instagram tag with
                      <em>#picturewham</em>
                      to print specific photos</p>
                  {{else}}
                    <p class={{styles.photos-description}}>
                      Add photos to Instagram to print them this month.
                    </p>
                  {{/if}}
                </div>
              {{/if}}

              <div class="{{styles.manage}} {{styles.box}}">
                <h2>Subscription</h2>
                <dl>
                  <dt>Plan</dt>
                  <dd>10 Print Plan</dd>
                  <dt>Status</dt>
                  <dd
                    class={{if
                      this.session.isSubscriptionActive
                      styles.active
                      styles.inactive
                    }}
                  >{{#if this.session.isSubscriptionActive}}Active
                      {{svgJar "tick"}}{{else}}Inactive
                      {{svgJar "cross"}}{{/if}}</dd>
                </dl>
                <button
                  class={{styles.btn}}
                  data-test-auth-manage-sub={{if
                    this.session.isSubscriptionActive
                    "active"
                    "inactive"
                  }}
                  type="button"
                  {{on "click" this.manageSubscription}}
                >Manage Subscription</button>
              </div>

              {{#unless this.session.hasInstagramToken}}
                <div
                  class="{{styles.authorize}} {{styles.box}}"
                  data-test-instagram-auth-alert
                  role="alert"
                >
                  <h2>Authorization</h2>
                  <a
                    class="{{styles.btn}} {{styles.insta}}"
                    data-test-auth-instagram-alert-link
                    href={{instagramAuthUrl}}
                  >Connect with Instagram</a>
                </div>
              {{/unless}}
            </div>
          </Section>
        </Sections>
      </:main>
    </Container>
  </template>
}

export default RouteTemplate(AuthenticatedIndexRouteComponent);
