import Component from '@glimmer/component';
import styles from './login-form.css';
import Link from './link';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import preventDefault from 'ember-event-helpers/helpers/prevent-default';

export default class LoginForm extends Component {
  get formMessage() {
    console.log(styles);
    window.styles = styles;
    console.log(styles.form);
    if (this.isInfo) {
      return 'Email is being delivered';
    } else if (this.isSuccess) {
      return 'Check your email for a login link';
    } else if (this.isError) {
      return 'Sorry there was an error';
    }
    return '';
  }

  get isSuccess() {
    return this.args.submitTaskInstance?.isSuccessful;
  }

  get isInfo() {
    return this.args.submitTaskInstance?.isRunning;
  }

  get isError() {
    return this.args.submitTaskInstance?.isError;
  }

  <template>
    <form class={{styles.form}} {{on "submit" (preventDefault @onSubmit)}}>
      <fieldset>
        <legend class="">Login</legend>
        <label class="sr-only" for="email">Email</label>
        <input
          placeholder="Enter your email"
          type="email"
          id="email"
          name="email"
          required="true"
          data-test-email-input
        />
        <Link
          class={{styles.submit}}
          type="submit"
          @button={{true}}
          data-test-login-button
        >Continue with Email</Link>

        <div
          aria-live="polite"
          class="{{styles.message}}
            {{if this.isSuccess styles.success}}
            {{if this.isError styles.error}}
            {{if this.isInfo styles.info}}"
          data-test-message
        >{{#if this.formMessage}}{{svgJar
              "letter"
            }}{{this.formMessage}}{{else}}&nbsp;{{/if}}</div>

        <p class={{styles.agree}}>By signing up, your agree to our
          <strong><LinkTo @route="legal.terms-of-use">Term of Use</LinkTo></strong>
          and acknowledge you've read our
          <strong><LinkTo @route="legal.privacy">Privacy Policy</LinkTo></strong></p>
      </fieldset>
    </form>
  </template>
}
