import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { LinkTo } from '@ember/routing';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { service } from '@ember/service';

import Container from 'frontend/components/container';
import Cost from 'frontend/components/cost';
import GetStartedButton from 'frontend/components/get-started-button';
import Header from 'frontend/components/header';
import Link from 'frontend/components/link';
import Picture from 'frontend/components/picture';
import Section from 'frontend/components/section';
import SectionFooter from 'frontend/components/section-footer';
import Sections from 'frontend/components/sections';

import styles from './index.css';

class IndexRouteComponent extends Component {
  @service api;
  @service session;

  @action logout() {
    this.session.invalidate();
  }

  <template>
    <Container class={{styles.index}}>
      <:header>
        <Header>
          <LinkTo
            data-test-menu-link
            class={{styles.portrait}}
            aria-label="Show menu"
            @route="menu"
          >{{svgJar "btn-menu"}}</LinkTo>
          {{#if this.session.hasEmail}}
            <Link
              data-test-logout-link
              class="{{styles.landscape}} {{styles.login-btn}}"
              @button={{true}}
              {{on "click" this.logout}}
            ><div class={{styles.login-txt}}>Logout</div></Link>
          {{else}}
            <Link
              data-test-login-link
              class="{{styles.landscape}} {{styles.login-btn}}"
              @route="login"
            ><div class={{styles.login-txt}}>Login</div></Link>
          {{/if}}
        </Header>
      </:header>
      <:main>
        <Sections>
          <Section id="intro" class="{{styles.intro}} {{styles.section}}">
            <Picture
              class="{{styles.picture}} {{styles.ls-order-one}}"
              @alt="Peering over tabletop at pictures swirling out of your phone"
              @width="1600"
              @height="849"
              @src="/images/illustrations/intro"
            />
            <div class="{{styles.side-text}} {{styles.ls-order-zero}}">
              <p class={{styles.caption}}>The
                <strong>ultimate</strong>
                photo subscription service for printing copies of your Instagram
                images!</p>

              <p class={{styles.info}}>Are you tired of only seeing your
                favourite memories on your phone or computer screen?</p>
              <p class="{{styles.info}} {{styles.life}}"><strong>Picture Wham™</strong>
                is here to help you bring those memories to life!</p>

              <GetStartedButton
                @hasInstagramToken={{this.session.hasInstagramToken}}
              />
            </div>
          </Section>

          <Section
            id="frequency"
            class="{{styles.frequency}} {{styles.section}}"
          >
            <Picture
              class="{{styles.picture}}
                {{styles.ls-order-zero}}
                {{styles.frequency-picture}}"
              @alt="Holding a physical print of picture"
              @width="1600"
              @height="2014"
              @loading="lazy"
              @src="/images/illustrations/frequency"
            />
            <div class="{{styles.side-text}} {{styles.ls-order-one}}">
              <p class={{styles.caption}}>Every month, 10 images that you have
                posted to your Instagram feed are printed on premium papers that
                allow for
                <strong>incredible depth and beautifully vibrant colours.</strong></p>

              <GetStartedButton
                @hasInstagramToken={{this.session.hasInstagramToken}}
              />
            </div>
          </Section>

          <Section id="usage" class="{{styles.usage}} {{styles.section}}">
            <Picture
              class="{{styles.picture}} {{styles.ls-order-one}}"
              @alt="Hugging fridge with pictures on it"
              @width="1600"
              @height="2642"
              @loading="lazy"
              @src="/images/illustrations/usage"
            />
            <div class="{{styles.side-text}} {{styles.ls-order-zero}}">
              <div class={{styles.caption}}><p>Our
                  <strong>4 by 4</strong>
                  inch prints are the perfect size to display on your fridge,
                  pinboard, or even in a small photo album.</p></div>

              <GetStartedButton
                @hasInstagramToken={{this.session.hasInstagramToken}}
              />
            </div>
          </Section>

          <Section id="hashtag" class="{{styles.hashtag}} {{styles.section}}">
            <p />
            <img
              width="914"
              height="201"
              class={{styles.hashtag-animated}}
              alt="The #picturewham hash tag is animated across the screen"
              loading="lazy"
              src="images/hashtag.gif"
            />
            <p class={{styles.caption}}>Want to print specific images? Write a
              caption containing the
              <strong>#picturewham</strong>
              tag and watch the magic unfold!</p>

            <GetStartedButton
              @darkBackground={{true}}
              @hasInstagramToken={{this.session.hasInstagramToken}}
            />
          </Section>

          <Section id="price" class="{{styles.price}} {{styles.section}}">
            <div class="{{styles.window}} {{styles.portrait}}">
              <p class={{styles.cost}}>
                {{svgJar "selected"}}
                <Cost />
              </p>
              <p class={{styles.caption}}>Display your favorite memories
                <strong>without breaking the bank!</strong></p>
              <ul class={{styles.list}}>
                <li>Print
                  <strong>10</strong>
                  stunning of 4 by 4 inch (10cm by 10cm) photos delivered to
                  your door every month!</li>
                <li>Easily preserve your cherished Instagram moments.</li>
                <li>Relive your travel adventures, capture precious family
                  milestones, create beautiful wall decor and much more!</li>
              </ul>
            </div>

            <GetStartedButton
              class={{styles.portrait}}
              @darkBackground={{true}}
              @hasInstagramToken={{this.session.hasInstagramToken}}
            />

            <div class="{{styles.window}} {{styles.landscape}}">
              <p class={{styles.cost}}>
                {{svgJar "selected"}}
                <Cost />
                <div />
              </p>
              <div>
                <p class={{styles.caption}}>Display your favorite memories
                  <strong>without breaking the bank!</strong></p>
                <ul class={{styles.list}}>
                  <li>Print
                    <strong>10</strong>
                    stunning of 4 by 4 inch (10cm by 10cm) photos delivered to
                    your door every month!</li>
                  <li>Easily preserve your cherished Instagram moments.</li>
                  <li>Relive your travel adventures, capture precious family
                    milestones, create beautiful wall decor and much more!</li>
                </ul>
                <GetStartedButton
                  @hasInstagramToken={{this.session.hasInstagramToken}}
                />
              </div>
            </div>
          </Section>

          <Section id="abyss" class="{{styles.abyss}} {{styles.section}}">
            <Picture
              class="{{styles.picture}}
                {{styles.ls-order-zero}}
                {{styles.abyss-picture}}"
              @alt="Looking into the digital abyss as it swirls around the #ootd"
              @width="1600"
              @height="1526"
              @loading="lazy"
              @src="/images/illustrations/abyss"
            />
            <div class="{{styles.side-text}} {{styles.ls-order-one}}">
              <div class={{styles.caption}}><p>Don’t let your Instagram images
                  get lost in the digital abyss – join Picture Wham today and
                  start
                  <strong>enjoying your memories in print!</strong></p></div>

              <GetStartedButton
                @hasInstagramToken={{this.session.hasInstagramToken}}
              />
            </div>
          </Section>

          <SectionFooter class="section" />

        </Sections>
      </:main>
    </Container>
  </template>
}
export default RouteTemplate(IndexRouteComponent);
